const Loader = ({ full, transparent }: { full: boolean, transparent?: boolean }) => (
  <div
    className={`main inset-0 flex items-center justify-center z-50 ${transparent ? '' : 'bg-main-background'}`}
    style={full ? { height: "100vh" } : {}}
  >
    <div className="loader ease-linear rounded-full border-gray-200 h-48 w-48 mb-4"></div>
    <style>
      {`
          .main {
            height: calc(100vh - 300px);
          }
          .loader {
            border-top-color: #3498db;
            width:70px;
            height:70px;
            animation: spin 1s linear infinite;
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
    </style>
  </div>
);

export default Loader;
